.app {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  height: 100vh;
  transition: filter 0.3s ease;
  z-index: 1;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  z-index: 3;
}

.popup-div {
  width: 200px;
  height: 200px;
  background-color: red;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100; /* Ensure it's above other elements */
}

.blur {
  position: relative;
  z-index: -1;
  filter: blur(5px); /* Apply blur to the body */

}
