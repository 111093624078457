body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: fit-content;
  overflow-x: hidden;
  /* background-color: #000; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#custom-nav {
  width: 100%;
}

.navbar-nav {
  display: flex;
  column-gap: 2vw;
  /* margin-left: 3vw; */
  margin-left: 39vw;
}

.nav-item {
  cursor: pointer;
  font-size: 1.2vw;
}

.nav-item>.nav-link {
  font-weight: bolder;
}

#ddl-service {
  width: 500px;
  height: 500px;
  background-color: rgba(255, 255, 255, .5);
  backdrop-filter: blur(20px);
  box-shadow: 2px 2px 200px 0 #aaa, -2px -2px 200px 0 #aaa;
  /* box-shadow: -1px -1px 20px 0 rgb(255, 145, 0), 3px 3px 20px 0 rgb(0, 225, 255); */
  display: none;
  position: relative;
  left: 5%;
  /* overflow: hidden; */
  /* transition: all .5s; */
  /* opacity: 0; */
  transition: opacity 0.5s ease-in-out;

  /* transform: translateY(50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out; */
}

#service:hover #ddl-service {
  display: block;
  position: absolute;
  transition: all .5s;
}

#about:hover #ddl-about {
  display: block;
  position: absolute;
  transition: all .5s;
}

@keyframes inAnimationDDL {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.fa-solid {
  transform: rotateZ(0deg);
  transition: all .3s;
}

#service:hover .fa-solid,
#about:hover .fa-solid {
  transform: rotateZ(-180deg);
}

#ddlServiceContainer {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.textTypeAnimation {
  /* width: 72%; */
  width: fit-content;
  position: relative;
  color: #ddd;
  -webkit-text-stroke: black;
  /* -webkit-text-stroke-width: 2px; */
  color: rgb(255, 145, 0);
  font-size: 5vw;
  font-weight: bolder;
  margin-bottom: 30px;
}

.textTypeAnimation::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  /* font-size: 5vw; */
  /* left: -10px; */
  width: 0%;
  height: 90%;
  /* font-weight: bolder; */
  color: rgb(0, 191, 255);
  /* border-right: 5px solid rgb(0, 191, 255); */
  overflow: hidden;
  animation: textAnimation 5s infinite alternate ease-in-out;
}

#business_solutinos {
  margin-top: -45px;
  /* margin-left: -30%; */
}

.textTypeAnimation_business {
  width: 100%;
  position: relative;
  /* bottom: 25px;
  left: 2px; */
  font-weight: bolder;
  color: rgb(255, 145, 0);
  -webkit-text-stroke: black;
  /* -webkit-text-stroke-width: 1px; */
  font-size: 1.88vw;
  font-weight: bolder;
}

.textTypeAnimation_business::before {
  content: attr(data-text);
  position: absolute;
  top: 3px;
  left: 0;
  /* font-size: 5vw; */
  /* left: -10px; */
  width: 100%;
  height: 90%;
  /* font-weight: bolder; */
  color: rgb(0, 191, 255);
  /* border-bottom: 3px solid blue; */
  overflow: hidden;
  animation: textAnimationVertical 5s infinite alternate ease-in-out;
}

.textTypeAnimation_solutions {
  width: 100%;
  position: relative;
  /* bottom: 25px;
  left: 1px; */
  font-weight: bolder;
  color: rgb(0, 191, 255);
  -webkit-text-stroke: black;
  /* -webkit-text-stroke-width: 1px; */
  font-size: 1.88vw;
  font-weight: bolder;
}

.textTypeAnimation_solutions::before {
  content: attr(data-text);
  position: absolute;
  /* top: 3px; */
  top: 3px;
  left: 0;
  /* font-size: 5vw; */
  /* left: -10px; */
  width: 100%;
  /* height: 90%; */
  height: fit-content;
  /* font-weight: bolder; */
  color: rgb(255, 145, 0);
  /* border-bottom: 3px solid blue; */
  overflow: hidden;
  animation: textAnimationVertical 5s infinite alternate ease-in-out;
}

.slideDownText {
  opacity: 0;
  animation: slideDown .2s 1 forwards ease-in;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.popUp {
  scale: 0;
  animation: popAnimation .2s 1 forwards ease-in-out;
}

#authContainer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: -12%;
  left: -25%;
  background-color: rgba(50, 50, 50, 0.9);
  z-index: 2000;
}

#auth {
  width: 30%;
  height: 450px;
  margin: 10% auto;
  position: relative;
  left: 0;
  top: 0;
  border-radius: 10px;
  padding: 20px;
  z-index: 3000;
  /* background-color: yellow; */
  background-image: linear-gradient(135deg, rgb(255, 145, 0), rgb(0, 191, 255));
}

/* 
.input,
.verification {
  border: none;
  outline: none;
  padding: 10px 20px;
} */

/* 
.input{
  width: 90%;
  border-radius: 30px;
} */


/* these */
/* .input {
  border: none;
  outline: none;
  padding: 10px 20px;
  width: 65%;
  margin: 5px 0;
  border-radius: 30px;
  border-radius: 30px 0 0 30px;
} */
/* 
.verifybtn {
  width: 25%;
  outline: none;
  border: none;
  background-image: linear-gradient(90deg, rgb(255, 145, 0), rgb(255, 255, 255), rgb(0, 191, 255));
  font-weight: bold;
  padding: 10px;
  border-radius: 0 30px 30px 0;
  transition: all .3s;
} */

/* .verifybtn:hover{
  filter: brightness(110%);
}

.verifybtn:active{
  filter: brightness(50%);
} */

.close {
  position: absolute;
  top: 10%;
  right: 30%;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

@keyframes popAnimation {
  from {
    scale: 0;
  }

  to {
    scale: 1;
  }
}

@keyframes textAnimation {
  0% {
    width: 0%;
  }

  50% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

@keyframes textAnimationVertical {
  0% {
    height: 15%;
  }

  50% {
    height: 90%;
  }

  100% {
    height: 15%;
  }
}



/* footer */
#facebook-icon,
#whatsapp-icon,
#linkedin-icon,
#instagram-icon,
#google-icon {
  padding: 5px;
  transform: scale(1.1);
  font-weight: 500;
  transition: all .3s ease-in-out;
}

#whatsapp-icon:hover {
  color: #25D366;
  font-weight: 500;
  transform: (1.2);
  filter: brightness(120%);
  transition: all 0.3s;
}

#facebook-icon:hover {
  color: #316FF6;
  filter: brightness(120%);
  transition: all .3s;
}

#linkedin-icon:hover {
  color: #0077B5;
  filter: brightness(120%);
  transition: all .3s;
}

#instagram-icon:hover {
  background-image: url(./image/instagram-bgImg.png);
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  filter: brightness(120%);
  transform: (1.2);
  transition: all .3s ease-in-out;
}


#google-icon:hover {
  background-image: url(./image/googlebackgorund.png);
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  filter: brightness(120%);
  color: transparent;
  transform: (1.2);
  transition: all .3s ease-in-out;
}

#internshala {
  width: 15px;
  filter: grayscale(100%);
  margin-bottom: 4px;
  transition: all .3s;
}

#internshala:hover {
  filter: grayscale(0);
}



/* contact us */
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}

/* 

body {
	background: #ffffff;
	color: #333;
	font-weight: normal;
	font-size: 1em;
	font-family: 'Roboto', Arial, sans-serif;
} */

input:focus,
textarea:focus,
keygen:focus,
select:focus {
  outline: none;
}


::-moz-placeholder {
  color: #666;
  font-weight: 300;
  opacity: 1;
}

::-webkit-input-placeholder {
  color: #666;
  font-weight: 300;
}

input:is(:hover, :focus, :active),
textarea:is(:hover, :focus, :active) {
  background-color: cyan;
}



/* Contact Form Styling */
.container {
  /* padding: 0 50px 70px; */
  padding: 0 50px 0;
  /* margin-b: ; */
}

.textcenter {
  text-align: center;
}

.section1 {
  text-align: center;
  display: table;
  width: 100%;
}

.section1 .shtext {
  display: block;
  margin-top: 20px;
}

.section1 .seperator {
  border-bottom: 1px solid #a2a2a2;
  width: 35px;
  display: inline-block;
  margin-top: 20px;
}

.section1 h1 {
  font-size: 40px;
  color: #A44DD8;
  font-weight: normal;
}

.section2 {
  width: 1200px;
  margin: 25px auto;
}

.section2 .col2 {
  width: 48.71%;
}

.section2 .col2.first {
  float: left;
}

.section2 .col2.last {
  float: right;
}

.section2 .col2.column2 {
  padding: 0 30px;
}

.section2 span.collig {
  color: #a2a2a2;
  margin-right: 10px;
  display: inline-block;
}

.section2 .sec2addr {
  display: block;
  line-height: 26px;
}

.section2 .sec2addr p:first-child {
  margin-bottom: 10px;
}

.section2 .sec2contactform input[type="text"],
.section2 .sec2contactform input[type="email"],
.section2 .sec2contactform textarea {
  padding: 18px;
  border: 0;
  background: #EDEDED;
  margin: 7px 0;
}

.section2 .sec2contactform textarea {
  width: 100%;
  display: block;
  color: #666;
  resize: none;
}

.section2 .sec2contactform input[type="submit"] {
  padding: 15px 40px;
  color: #fff;
  border: 0;
  background: #A44DD8;
  font-size: 16px;
  text-transform: uppercase;
  margin: 7px 0;
  cursor: pointer;
}

.section2 .sec2contactform h3 {
  font-weight: normal;
  margin: 20px 0;
  margin-top: 30px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 19px;
  color: #A44DD8;
}

/* @media querries */

@media only screen and (max-width: 1266px) {
  .section2 {
    width: 100%;
  }
}

@media only screen and (max-width: 960px) {
  .container {
    padding: 0 30px 70px;
  }

  .section2 .col2 {
    width: 100%;
    display: block;
  }

  .section2 .col2.first {
    margin-bottom: 10px;
  }

  .section2 .col2.column2 {
    padding: 0;
  }

  body .sec2map {
    height: 250px !important;
  }
}

@media only screen and (max-width: 768px) {
  .section2 .sec2addr {
    font-size: 14px;
  }

  .section2 .sec2contactform h3 {
    font-size: 16px;
  }

  .section2 .sec2contactform input[type="text"],
  .section2 .sec2contactform input[type="email"],
  .section2 .sec2contactform textarea {
    padding: 10px;
    margin: 3px 0;
  }

  .section2 .sec2contactform input[type="submit"] {
    padding: 10px 30px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 420px) {
  .section1 h1 {
    font-size: 28px;
  }
}

.btn {
  outline: none;
  border: none;
  background-image: linear-gradient(90deg, rgb(255, 145, 0), rgb(255, 255, 255), rgb(0, 191, 255));
  box-shadow: 0 5px 0 #aaa;
  font-weight: bolder;
  transition: all .3s;
  /* animation: linearGradientAnimation 1s infinite forwards ease; */
}

.btn:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 0px #aaa;
}

.btn:active {
  transform: scale(0.9);
  filter: brightness(80%);
  box-shadow: 0 1px 5px #000;
}

@keyframes linearGradientAnimation {
  0% {
    background-image: linear-gradient(90deg, rgb(0, 191, 255), rgb(255, 145, 0));
  }

  10% {
    background-image: linear-gradient(90deg, rgb(255, 145, 0), rgb(0, 191, 255));
  }
}

#submitbtn {
  transform: scale(0.9);
  transition: all 0.3s;
}

[type="submit"] {
  transform: scale(1);
  transition: all 0.3s;
}

[type="submit"]:hover,
#submitbtn:hover {
  transform: scale(1.1);
}

[type="submit"]:active,
#submitbtn:active {
  filter: brightness(80%);
  transform: scale(0.8);
}

#nav-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;

}

a {
  text-decoration: none;
  color: black;
  transition: all 300ms;
}

#nav-container>a>div {
  margin-top: 5px;
  padding: 0 20px;
  transform: scale(1);
  transition: all 0.3s;
}

#nav-container>a>div:hover {
  color: white;
}

.tescomTxtStyle {
  color: transparent;
  background-image: linear-gradient(90deg, rgb(0, 191, 255), rgb(255, 145, 0) 50%);
  /* -webkit-text-stroke: .5px black; */
  background-clip: text;
}

#tescomTxtStyle {
  color: transparent;
  background-image: linear-gradient(135deg, rgb(0, 191, 255) 50%, rgb(255, 145, 0) 1%);
  /* -webkit-text-stroke: .5px black; */
  background-clip: text;
}

#allservices {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
}

.service-nav-container {
  display: grid;
  grid-template-columns: auto;
  font-size: .8vw;
  font-weight: 700;
  margin: 0 1vw;
  /* grid-template-rows: auto ; */
  /* place-self: center; */
  /* font-size: 2vw; */
  transition: all 0.3s;
}

.service-nav-container>div>h1 {
  width: fit-content;
  margin-top: 5px;
  color: black;
  transition: all 0.3s;
  font-size: 0.9vw;
  font-weight: bolder;
}

.service-nav-container>div {
  transition: all 0.3s;
}

.service-nav-container>div:hover {
  width: fit-content;
  color: transparent;
  background-image: linear-gradient(135deg, rgb(0, 191, 255) 50%, rgb(255, 145, 0) 50%);
  /* background-color: red; */
  -webkit-text-stroke-color: black;
  background-clip: text;
  filter: brightness(110%);
}


.service-nav-container>.parentServices>div {
  display: none;
  width: 600px;
  height: 400px;
  border-radius: 5px;
  /* padding: 0 50px; */
  position: relative;
  left: 110%;
  top: 0%;
  clip-path: polygon(50% 0%, 100% 0, 100% 43%, 100% 100%, 51% 100%, 2% 100%, 2% 2%, 0 0, 0 0);
}


.service-nav-container>.parentServices:hover>div {
  display: block;
  color: black;
  margin-left: -10px;
  overflow: hidden;
  padding: 10px;
  padding-left: 20px;
  margin-top: 7px;
  /* opacity: 1; */
  /* left: 165px; */
  position: absolute;
  z-index: 1000;
  background-color: rgba(170, 170, 170, 1);
  /* background: linear-gradient(135deg, rgba(100, 100, 100, .5), rgba(100, 100, 100, 0.5)); */
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  /* transition: all 3s; */
}

.service-nav-container>.parentServices>h6 {
  width: fit-content;
  background-color: transparent;
  border-radius: 0px;
  transition: all .1s;
}

.service-nav-container>.parentServices:hover>h6 {
  width: fit-content;
  background-color: rgba(225, 225, 225, .2);
  font-weight: bold;
  border-radius: 10px;
}

/* .service-nav-container>.parentServices:hover>h1{
  position: relative;
  z-index: 10000;
} */

.service-nav-container>.parentServices>.right-top {
  display: none;
  width: 600px;
  height: 400px;
  border-radius: 5px;
  /* padding: 0 50px; */
  position: relative;
  left: 110%;
  top: 0%;
  /* clip-path: polygon(50% 0%, 100% 0, 100% 43%, 100% 100%, 51% 100%, 2% 100%, 2% 2%, 0 0, 0 0); */
  /* clip-path: polygon(96% 0, 100% 2%, 97% 4%, 97% 100%, 45% 100%, 30% 100%, 0 100%, 0 0); */
  clip-path: polygon(98% 0, 100% 0, 98% 3%, 98% 100%, 68% 100%, 32% 100%, 0 100%, 0 0);
}

.service-nav-container>.parentServices:hover>.right-top {
  display: block;
  color: black;
  margin-left: -10px;
  overflow: hidden;
  padding: 10px;
  padding-right: 20px;
  margin-top: 7px;
  /* opacity: 1; */
  /* background: red; */
  position: absolute;
  left: -575px;
  /* left: -380%; */
  /* left: 0%; */
  z-index: 1000;
  background-color: rgba(170, 170, 170, 1);
  /* background: linear-gradient(135deg, rgba(100, 100, 100, .5), rgba(100, 100, 100, 0.5)); */
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  /* transition: all 3s; */
}

.service-nav-container>.parentServices>.cloudndc {
  display: none;
  width: 600px;
  height: 400px;
  /* height: 100%; */
  border-radius: 5px;
  /* padding: 0 50px; */
  position: relative;
  left: 110%;
  top: 0%;
  /* clip-path: polygon(50% 0%, 100% 0, 100% 43%, 100% 100%, 51% 100%, 2% 100%, 2% 2%, 0 0, 0 0); */
  /* clip-path: polygon(96% 0, 100% 2%, 97% 4%, 97% 100%, 45% 100%, 30% 100%, 0 100%, 0 0); */
  /* clip-path: polygon(98% 0, 100% 0, 98% 3%, 98% 100%, 68% 100%, 32% 100%, 0 100%, 0 0); */
  clip-path: polygon(2% 0, 100% 0, 100% 100%, 68% 100%, 32% 100%, 0 100%, 2% 98%);
}

.service-nav-container>.parentServices:hover>.cloudndc {
  display: block;
  color: black;
  /* margin-left: -10px; */
  overflow: hidden;
  /* padding: 10px; */
  /* margin-top: 7px; */
  /* opacity: 1; */
  /* background: red; */
  position: absolute;
  /* left: -575px; */
  /* left: 120px; */
  left: 110%;
  /* bottom: 100%; */
  top: -395px;
  /* left: -380%; */
  /* left: 0%; */
  z-index: 1000;
  background-color: rgba(170, 170, 170, 1);
  /* background: linear-gradient(135deg, rgba(100, 100, 100, .5), rgba(100, 100, 100, 0.5)); */
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  /* transition: all 3s; */
}

.service-nav-container>.parentServices>.right-bottom {
  display: none;
  width: 600px;
  height: 400px;
  border-radius: 5px;
  /* padding: 0 50px; */
  position: relative;
  left: 110%;
  top: 0%;
  /* clip-path: polygon(50% 0%, 100% 0, 100% 43%, 100% 100%, 51% 100%, 2% 100%, 2% 2%, 0 0, 0 0); */
  /* clip-path: polygon(96% 0, 100% 2%, 97% 4%, 97% 100%, 45% 100%, 30% 100%, 0 100%, 0 0); */
  /* clip-path: polygon(98% 0, 100% 0, 98% 3%, 98% 100%, 68% 100%, 32% 100%, 0 100%, 0 0); */
  clip-path: polygon(0 0, 70% 0%, 98% 0, 98% 97%, 100% 100%, 30% 100%, 0 100%, 0% 30%);
}

.service-nav-container>.parentServices:hover>.right-bottom {
  display: block;
  color: black;
  margin-left: -10px;
  overflow: hidden;
  padding: 10px;
  margin-top: 7px;
  padding-right: 20px;
  /* opacity: 1; */
  /* background: red; */
  position: absolute;
  left: -575px;
  top: -345px;
  /* left: -380%; */
  /* left: 0%; */
  z-index: 1000;
  background-color: rgba(170, 170, 170, 1);
  /* background: linear-gradient(135deg, rgba(100, 100, 100, .5), rgba(100, 100, 100, 0.5)); */
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  /* transition: all 3s; */
}

/* .parentServices:hover>div.right-top{
  left: 410%;
}
/* .service-nav-container>.parentServices:hover>div { 
.service-nav-container>.parentServices:hover>.right-top {
} */

/* .glassOnly {
  background: linear-gradient(135deg, rgba(255, 255, 255, .1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  transition: all 3s;
} */

#nav-options {
  flex-grow: 2;
  width: 66.6%;
  padding: 10px;
}


.imgTag {
  transform: scale(0.5);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  border: 2px solid transparent;
}

.slide {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-self: center;
}

.stateInstallation:hover {
  background-color: #eee;
}

#aboutCircleImg,
.imageCircle {
  content: attr(' ');
  position: relative;
  scale: 1;
  border: 10px solid tranparent;
  border-radius: 50%;
  background-image: linear-gradient(0deg, rgb(0, 191, 255) 10%, rgb(255, 255, 255), rgb(255, 145, 0));
  padding: 5px;
  box-shadow: 0 0 10px 1px #aaa;
  transition: background-image 300ms;
  /* transform: rotateZ(90deg); */
  animation: circleImgAnimation 3s infinite forwards linear;
}

.hide {
  display: none;
}

input,
textarea {
  /* outline: none; */
  /* opacity: 0; */
  scale: 1;
  border-radius: 10px;
}

input::placeholder,
textarea::placeholder {
  font-weight: bolder;
  box-shadow: 1px 1px 10px 0 transparent, -1px -1px 10px 0 transparent;
  color: #aaa;
}

input:focus,
textarea:focus {
  /* outline: 1px dashed black; */
  scale: 1.02;
  box-shadow: 1px 1px 10px 0 #aaa, -1px -1px 10px 0 #ddd;
  transition: scale .5s ease-in-out, box-shadow .3s ease-in-out;
}

#upload-resume {
  width: 130px;
  padding: 2px;
  border-radius: 5px;
  background-color: #eee;
  transform: scale(1.6);
  margin: auto;
  /* margin: 0 auto 10px; */
  /* margin-left: 14vw; */
  transition: all .5s;
}

#upload-resume:hover {
  transform: scale(1.7);
  background-color: #ddd;
}

#upload-resume:active {
  transform: scale(1.5);
  background-color: #aaa;
}

fieldset {
  border: 10px solid transparent;
  box-sizing: border-box;
  margin-top: -50px;
  grid-area: 1 / 1;
  /* first row, first column */
  padding: 20px;
  width: inherit;
}

#career-submit-btn {
  width: 30%;
  margin: 20px auto;
  /* background-color: rgba(0, 255, 30, .7);
  color: rgb(0, 85, 10);
  font-weight: bolder;
  border-radius: 10px;
  margin: auto;
  margin-top: 50px;
  padding: 7px 5px;
  /* border: 1px solid black; 
  border: none;
  box-shadow: 0 4px 0 0 rgb(0, 85, 10);
  transform: scale(1); */
  transition: all 0.3s;
}

#career-submit-btn:hover {
  transform: scale(1.1);
}

#career-submit-btn:active {
  transform: scale(0.9);
}

/* #tescomImgs>img {
  width: 60vw;
} */



@keyframes circleImgAnimation {
  0% {
    background-image: linear-gradient(0deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  1% {
    background-image: linear-gradient(3deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  2% {
    background-image: linear-gradient(6deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  3% {
    background-image: linear-gradient(9deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  4% {
    background-image: linear-gradient(12deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  5% {
    background-image: linear-gradient(15deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  6% {
    background-image: linear-gradient(18deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  7% {
    background-image: linear-gradient(21deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  8% {
    background-image: linear-gradient(24deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  9% {
    background-image: linear-gradient(27deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  10% {
    background-image: linear-gradient(30deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  11% {
    background-image: linear-gradient(33deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  12% {
    background-image: linear-gradient(36deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  13% {
    background-image: linear-gradient(39deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  14% {
    background-image: linear-gradient(42deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  15% {
    background-image: linear-gradient(45deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  16% {
    background-image: linear-gradient(48deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  17% {
    background-image: linear-gradient(51deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  18% {
    background-image: linear-gradient(54deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  19% {
    background-image: linear-gradient(57deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  20% {
    background-image: linear-gradient(60deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  21% {
    background-image: linear-gradient(63deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  22% {
    background-image: linear-gradient(66deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  23% {
    background-image: linear-gradient(69deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  24% {
    background-image: linear-gradient(72deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  25% {
    background-image: linear-gradient(75deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  26% {
    background-image: linear-gradient(78deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  27% {
    background-image: linear-gradient(81deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  28% {
    background-image: linear-gradient(84deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  29% {
    background-image: linear-gradient(87deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  30% {
    background-image: linear-gradient(90deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  31% {
    background-image: linear-gradient(93deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  32% {
    background-image: linear-gradient(96deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  33% {
    background-image: linear-gradient(99deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  34% {
    background-image: linear-gradient(102deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  35% {
    background-image: linear-gradient(105deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  36% {
    background-image: linear-gradient(108deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  37% {
    background-image: linear-gradient(111deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  38% {
    background-image: linear-gradient(114deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  39% {
    background-image: linear-gradient(117deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  40% {
    background-image: linear-gradient(120deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  41% {
    background-image: linear-gradient(123deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  42% {
    background-image: linear-gradient(126deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  43% {
    background-image: linear-gradient(129deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  44% {
    background-image: linear-gradient(132deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  45% {
    background-image: linear-gradient(135deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  46% {
    background-image: linear-gradient(138deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  47% {
    background-image: linear-gradient(141deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  48% {
    background-image: linear-gradient(144deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  49% {
    background-image: linear-gradient(147deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  50% {
    background-image: linear-gradient(150deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  51% {
    background-image: linear-gradient(153deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  52% {
    background-image: linear-gradient(156deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  53% {
    background-image: linear-gradient(159deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  54% {
    background-image: linear-gradient(162deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  55% {
    background-image: linear-gradient(165deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  56% {
    background-image: linear-gradient(168deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  57% {
    background-image: linear-gradient(171deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  58% {
    background-image: linear-gradient(174deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  59% {
    background-image: linear-gradient(177deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  60% {
    background-image: linear-gradient(180deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  61% {
    background-image: linear-gradient(183deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  62% {
    background-image: linear-gradient(186deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  63% {
    background-image: linear-gradient(189deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  64% {
    background-image: linear-gradient(192deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  65% {
    background-image: linear-gradient(195deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  66% {
    background-image: linear-gradient(198deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  67% {
    background-image: linear-gradient(201deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  68% {
    background-image: linear-gradient(204deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  69% {
    background-image: linear-gradient(207deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  70% {
    background-image: linear-gradient(210deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  71% {
    background-image: linear-gradient(213deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  72% {
    background-image: linear-gradient(216deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  73% {
    background-image: linear-gradient(219deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  74% {
    background-image: linear-gradient(222deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  75% {
    background-image: linear-gradient(225deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  76% {
    background-image: linear-gradient(228deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  77% {
    background-image: linear-gradient(231deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  78% {
    background-image: linear-gradient(234deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  79% {
    background-image: linear-gradient(237deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  80% {
    background-image: linear-gradient(243deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  81% {
    background-image: linear-gradient(246deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  82% {
    background-image: linear-gradient(249deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  83% {
    background-image: linear-gradient(252deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  84% {
    background-image: linear-gradient(255deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  85% {
    background-image: linear-gradient(258deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  86% {
    background-image: linear-gradient(261deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  87% {
    background-image: linear-gradient(264deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  88% {
    background-image: linear-gradient(267deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  89% {
    background-image: linear-gradient(270deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  90% {
    background-image: linear-gradient(273deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  91% {
    background-image: linear-gradient(276deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  92% {
    background-image: linear-gradient(279deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  93% {
    background-image: linear-gradient(282deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  94% {
    background-image: linear-gradient(285deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  95% {
    background-image: linear-gradient(288deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  96% {
    background-image: linear-gradient(291deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  97% {
    background-image: linear-gradient(294deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  98% {
    background-image: linear-gradient(300deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  99% {
    background-image: linear-gradient(320deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }

  100% {
    background-image: linear-gradient(340deg, rgb(0, 191, 255), rgb(255, 255, 255), rgb(255, 145, 0));
  }
}

/* @media only screen and (max-height: 700px) {
  #tescomDetails {
    position: relative;
    top: -150px;
}
} */

@media only screen and (min-width: 200px) and (max-width: 475px) {
  #tescomImgs>img {
    width: 90vw;
    margin: auto;
  }

  #tescomDetails {
    position: relative;
    /* top: -225px; */
    top: -25vh;
  }

  #homePrimary {
    margin: 0px auto -325px;
  }

  #tescomDetails>h1 {
    margin-top: 10px;
    font-size: 5em;
  }

  #tescomDetails>h2>b {
    font-size: 1.1599em;
  }

  #homePrimary {
    position: relative;
    top: 30px;
  }

  #aboutprimary {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  #abouttextprimary {
    text-align: center;
    /* margin-left: 10% ; */
    font-size: 10vw;
    font-weight: bold;
  }

  .circle>img {
    width: 98%
  }

  #ulList>li:nth-child(2) {
    margin-left: 0px;
  }

  #ulList>li:nth-child(3) {
    margin-left: 0px;
  }

  /* #carousel>center{
    display: none;
  } */
  #aboutSection2Txt>h1 {
    font-size: 2vw;
  }

  #aboutSection2Txt>p {
    font-size: 1.5;
  }

  #aboutSection2Txt>ul {
    font-size: 1vw;
  }

  #aboutSection2Txt>h1 {
    position: relative;
    left: 0px;
    white-space: nowrap;
    font-size: 2.2vw;
  }

  #abouttextprimary>p {
    font-size: .4em;
  }

  #aboutSection2Txt>p {
    font-size: 1.1em;
    text-align: center;
  }

  #aboutSection2Txt>ul {
    font-size: 1em;
    word-break: break-all;
    position: relative;
    left: -20px;
    /* white-space: wrap; */
  }

  #aboutSection2Txt>h1 {
    position: relative;
    left: 0px;
    white-space: nowrap;
    font-size: 1.2em;
    text-align: center;
  }

  #section3Txt {
    width: 60%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    font-size: 4.5vw;
    font-weight: bolder;
  }

  #table {
    width: 97%;
    margin: 10px auto 10px;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0 0 10px #aaa;
  }

  #left-bottom-circle,
  #top-right-circle {
    position: absolute;
  }

  #left-bottom-circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    top: 12vh;
    left: 0vw;
    z-index: -100;
    /* border: 2px solid black; */
    background-color: rgb(255, 145, 0);
    box-shadow: inset 0 0 100px 0 rgb(255, 173, 66), 5px 5px 20px 0 rgb(255, 173, 66);
    animation: scaleAnimation 2s infinite alternate ease-in-out;
    /* box-shadow: inset -5px -5px 20px rgb(255, 173, 66),5px 5px 20px 0 #aaa, inset 5px 5px 20px rgb(161, 91, 0); */
  }

  #top-right-circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    bottom: 12vh;
    right: 0vw;
    z-index: -100;
    /* border: 2px solid black; */
    background-color: rgb(0, 191, 255);
    box-shadow: inset -5px -5px 20px rgb(85, 213, 255), 5px 5px 20px 0 rgb(85, 213, 255);
    animation: scaleAnimation 2s infinite alternate ease-in-out;
    /* box-shadow: inset -5px -5px 20px rgb(255, 173, 66),5px 5px 20px 0 #aaa, inset 5px 5px 20px rgb(161, 91, 0); */
  }

  #careerFormContainer {
    width: 95%;
    display: flex;
    overflow: hidden;
    /* flexDirection: document.body.offsetWidth > 900 ? row: column;  */
    flex-direction: column;
    padding: 0;
    margin: auto;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0 #aaa;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(50px);
  }
}

@media only screen and (min-width: 475px) {
  #tescomImgs>img {
    width: 90vw;
    /* scale */
    margin: auto;
  }

  #tescomDetails {
    width: 95%;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* margin-left: 3%; */
    position: relative;
    bottom: 150px;
    z-index: 50;
  }

  #tescomDetails>h1 {
    margin-top: 10px;
    font-size: 1.25em;
  }

  #tescomDetails>h2>b {
    font-size: 1.3em;
  }

  #aboutprimary {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  #tescomDetails>h1 {
    margin-top: 10px;
    font-size: 5.7em;
    /* font-size: 2em; */
  }

  #tescomDetails>h2>b {
    font-size: 1.25em;
  }

  #abouttextprimary {
    text-align: center;
    /* margin-left: 10% ; */
    font-size: 10vw;
    font-weight: bold;
  }

  .circle>img {
    width: 97%
  }

  #ulList>li:nth-child(2) {
    margin-left: 0px;
  }

  #ulList>li:nth-child(3) {
    margin-left: 0px;
  }

  #aboutSection2Txt>h1 {
    font-size: 2vw;
  }

  /* 
  #aboutSection2Txt>p {
    font-size: 1.5;
  }

  #aboutSection2Txt>ul {
    font-size: 1vw;
  }

  #aboutSection2Txt>h1 {
    position: relative;
    left: 0px;
    white-space: nowrap;
    font-size: 2.2vw;
  }
 */
  #aboutSection2Txt>p {
    font-size: 1.2em;
    text-align: center;
  }

  #aboutSection2Txt>ul {
    font-size: 1.1em;
    word-break: break-all;
    position: relative;
    left: -20px;
    /* white-space: wrap; */
  }

  #aboutSection2Txt>h1 {
    position: relative;
    left: 0px;
    white-space: nowrap;
    font-size: 1.5em;
    text-align: center;
  }

  /* 
  #abouttextprimary>h1{
    font-size: 5em;
    word-spacing: normal;
  }*/

  #abouttextprimary>p {
    font-size: .4em;
  }

  #section3Txt {
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    font-size: 4vw;
    font-weight: bolder;
  }

  #table {
    width: 95%;
    margin: 10px auto 20px;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0 0 10px #aaa;
  }

  #left-bottom-circle,
  #top-right-circle {
    position: absolute;
  }

  #left-bottom-circle {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    top: 0vh;
    left: 0vw;
    z-index: -100;
    /* border: 2px solid black; */
    background-color: rgb(255, 145, 0);
    box-shadow: inset 0 0 100px 0 rgb(255, 173, 66), 5px 5px 20px 0 rgb(255, 173, 66);
    animation: scaleAnimation 2s infinite alternate ease-in-out;
    /* box-shadow: inset -5px -5px 20px rgb(255, 173, 66),5px 5px 20px 0 #aaa, inset 5px 5px 20px rgb(161, 91, 0); */
  }

  #top-right-circle {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    bottom: 0vh;
    right: 0vw;
    z-index: -100;
    /* border: 2px solid black; */
    background-color: rgb(0, 191, 255);
    box-shadow: inset -5px -5px 20px rgb(85, 213, 255), 5px 5px 20px 0 rgb(85, 213, 255);
    animation: scaleAnimation 2s infinite alternate ease-in-out;
    /* box-shadow: inset -5px -5px 20px rgb(255, 173, 66),5px 5px 20px 0 #aaa, inset 5px 5px 20px rgb(161, 91, 0); */
  }

  #careerFormContainer {
    width: 95%;
    display: flex;
    overflow: hidden;
    /* flexDirection: document.body.offsetWidth > 900 ? row: column;  */
    flex-direction: column;
    padding: 0;
    margin: auto;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0 #aaa;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(50px);
  }
}

@media only screen and (min-width: 640px) {
  #tescomImgs>img {
    /* width: 45vw; */
    margin: 0px auto;
  }

  #tescomDetails {
    /* width: 60%; */
    /* width: 40%; */
    width: 80%;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* margin-left: 3%; */
    position: relative;
    bottom: 0px;
    z-index: 50;
    font-size: 5em;
  }


  #aboutprimary {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  #abouttextprimary {
    text-align: center;
    /* margin-left: 10% ; */
    font-size: 6.2vw;
    font-weight: bold;
  }


  #tescomDetails>h1 {
    margin-top: 10px;
    font-size: 1.15em;
    /* font-size: 2em; */
  }

  #tescomDetails>h2>b {
    font-size: 1.25em;
  }

  .circle>img {
    width: 95%
  }

  #ulList>li:nth-child(2) {
    margin-left: 0px;
  }

  #ulList>li:nth-child(3) {
    margin-left: 0px;
  }

  /* #aboutSection2Txt>h1 {
    font-size: 3vw;
  } */

  #aboutSection2Txt>p {
    font-size: 1.5em;
    text-align: center;
  }

  #aboutSection2Txt>ul {
    font-size: 1em;
    word-break: break-all;
    position: relative;
    left: -20px;
    /* white-space: wrap; */
  }

  #aboutSection2Txt>h1 {
    position: relative;
    left: 0px;
    white-space: nowrap;
    font-size: 2em;
    text-align: center;
  }

  /* #abouttextprimary>h1 {
    font-size: 1em;
    /* word-spacing: normal; 
  }*/
  #aboutSection2Txt {
    margin-left: 25px;
  }

  #abouttextprimary>p {
    font-size: .5em;
  }

  /* 
  #primary-home{
    /* width: 95%; */
  /* height: 80vh; */
  /* display: flex; */
  /* flex-direction:row; 
    margin: 0 auto -50px;
    /* justify-content: space-between;
    align-items: center;
    border-bottom: 3px solid #ddd; 
  } */
  #section3Txt {
    width: 30%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    font-size: 2.5vw;
    font-weight: bolder;
  }

  #table {
    width: 90%;
    margin: 10px auto 30px;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0 0 10px #aaa;
  }

  #joinourTeam {
    font-size: 2.7vw;
    word-break: break-all;
    margin-bottom: -100px;
  }

  #left-bottom-circle,
  #top-right-circle {
    position: absolute;
  }

  #left-bottom-circle {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    top: 0vh;
    left: 0vw;
    z-index: -100;
    /* border: 2px solid black; */
    background-color: rgb(255, 145, 0);
    box-shadow: inset 0 0 100px 0 rgb(255, 173, 66), 5px 5px 20px 0 rgb(255, 173, 66);
    animation: scaleAnimation 2s infinite alternate ease-in-out;
    /* box-shadow: inset -5px -5px 20px rgb(255, 173, 66),5px 5px 20px 0 #aaa, inset 5px 5px 20px rgb(161, 91, 0); */
  }

  #top-right-circle {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    bottom: 0vh;
    right: 0vw;
    z-index: -100;
    /* border: 2px solid black; */
    background-color: rgb(0, 191, 255);
    box-shadow: inset -5px -5px 20px rgb(85, 213, 255), 5px 5px 20px 0 rgb(85, 213, 255);
    animation: scaleAnimation 2s infinite alternate ease-in-out;
    /* box-shadow: inset -5px -5px 20px rgb(255, 173, 66),5px 5px 20px 0 #aaa, inset 5px 5px 20px rgb(161, 91, 0); */
  }

  #careerFormContainer {
    width: 90%;
    display: flex;
    overflow: hidden;
    /* flexDirection: document.body.offsetWidth > 900 ? row: column;  */
    flex-direction: column;
    padding: 0;
    margin: auto;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0 #aaa;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(50px);
  }
}

@media only screen and (min-width: 786px) {
  .navbar-nav {
    display: flex;
    column-gap: 1.5vw;
    /* margin-left: 3vw; */
    margin-left: 0;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .section1 h1 {
    font-size: 28px
  }

  #nav-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    place-self: center;
    font-size: 2vw;
    /* grid-template-columns: 50% 50%;
  grid-template-rows: 25% 25% 25% 25%; */
  }

  #tescomImgs>img {
    width: 45vw;
    transform: scale(1.3);
    position: relative;
    right: 100px;
    margin: auto;
  }

  #tescomDetails {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 3%;
    position: relative;
    bottom: 20px;
    z-index: 50;
  }

  #aboutprimary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10%;
  }

  #abouttextprimary>h1 {
    font-size: .8em;
    white-space: nowrap;
  }

  #abouttextprimary>p {
    font-size: .4em;
  }

  #tescomDetails>h1 {
    margin-top: 10px;
    font-size: 1em;
    /* font-size: 2em; */
  }

  #tescomDetails>h2>b {
    font-size: 0.97em;
  }

  .circle>img {
    width: 90%
  }

  #aboutImg2>img {
    width: 95%;
  }

  #aboutSection2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 5%;
    flex-direction: column;
  }

  #ulList>li:nth-child(2) {
    margin-left: 0px;
  }

  #ulList>li:nth-child(3) {
    margin-left: 0px;
  }

  #aboutSection2Txt {
    /* text-align: center;
    position: relative; */
    margin-left: 25px;
  }

  #aboutSection2Txt>h1 {
    position: relative;
    left: 0px;
    white-space: nowrap;
    font-size: 2.2vw;
  }

  #aboutSection2Txt>p {
    font-size: 1.4em;
  }

  #aboutSection2Txt>ul {
    font-size: 1.5vw;
    text-align: center;
  }

  #section3Txt {
    width: 20%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    font-size: 1.5vw;
    font-weight: bolder;
  }

  #table {
    width: 60%;
    margin: 10px auto 50px;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0 0 10px #aaa;
  }

  #joinourTeam {
    font-size: 2.7vw;
    word-break: break-all;
    margin-bottom: -100px;
  }

  #left-bottom-circle,
  #top-right-circle {
    position: absolute;
  }

  #left-bottom-circle {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    top: 0vh;
    left: 0vw;
    z-index: -100;
    /* border: 2px solid black; */
    background-color: rgb(255, 145, 0);
    box-shadow: inset 0 0 100px 0 rgb(255, 173, 66), 5px 5px 20px 0 rgb(255, 173, 66);
    animation: scaleAnimation 2s infinite alternate ease-in-out;
    /* box-shadow: inset -5px -5px 20px rgb(255, 173, 66),5px 5px 20px 0 #aaa, inset 5px 5px 20px rgb(161, 91, 0); */
  }

  #top-right-circle {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    bottom: 0vh;
    right: 0vw;
    z-index: -100;
    /* border: 2px solid black; */
    background-color: rgb(0, 191, 255);
    box-shadow: inset -5px -5px 20px rgb(85, 213, 255), 5px 5px 20px 0 rgb(85, 213, 255);
    animation: scaleAnimation 2s infinite alternate ease-in-out;
    /* box-shadow: inset -5px -5px 20px rgb(255, 173, 66),5px 5px 20px 0 #aaa, inset 5px 5px 20px rgb(161, 91, 0); */
  }

  #careerFormContainer {
    width: 70%;
    display: flex;
    overflow: hidden;
    /* flexDirection: document.body.offsetWidth > 900 ? row: column;  */
    flex-direction: column;
    padding: 0;
    margin: auto;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0 #aaa;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(50px);
  }
}

@media only screen and (min-width: 1024px) {
  .navbar-nav {
    display: flex;
    column-gap: 1vw;
    /* margin-left: 3vw; */
    margin-left: 30vw;
  }

  #tescomImgs>img {
    width: 45vw;
    transform: scale(1.3);
    position: relative;
    right: 100px;
    margin: auto;
  }

  #tescomDetails {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 3%;
    position: relative;
    bottom: 20px;
    z-index: 50;
  }


  #aboutprimary {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    /* flex-direction: row; */
  }

  #abouttextprimary {
    text-align: center;
    margin-left: 10%;
    font-size: 5vw;
  }

  #abouttextprimary>h1 {
    font-size: .7em;
    white-space: nowrap;
  }

  #abouttextprimary>p {
    font-size: .35em;
  }


  #tescomDetails>h1 {
    margin-top: 10px;
    font-size: 1.15em;
    /* font-size: 2em; */
  }

  #tescomDetails>h2>b {
    font-size: 1.1em;
  }

  .circle>img {
    width: 60%;
  }

  #aboutImg2 {
    width: 95%;
    clip-path: polygon(0px 1%, 80% 0%, 100% 100%, 0% 100%);
  }

  #aboutSection2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 5%;
    flex-direction: row;
  }

  #ulList>li:nth-child(2) {
    margin-left: 10px;
  }

  #ulList>li:nth-child(3) {
    margin-left: 30px;
  }

  #aboutSection2Txt {
    text-align: center;
    position: relative;
    margin-left: -100px;
  }

  #aboutSection2Txt>h1 {
    position: relative;
    left: -30px;
    white-space: nowrap;
    font-size: 2.2vw;
  }

  #aboutSection2Txt>p {
    font-size: 1.4;
  }

  #aboutSection2Txt>ul {
    font-size: 1.5vw;
  }

  /* #abouttextprimary>h1 {
    font-size: .6em;
    word-spacing: normal;
  }

  #abouttextprimary>p {
    font-size: .3em;
  } */

  /* #tescomImgs>img {
    width: 95%;
    height: 80vh;
    display: flex;
    flex-direction: column-reverse;
    margin: 0px auto 0px;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid rgb(221, 221, 221);
  } */

  #section3Txt {
    width: 20%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    font-size: 1.5vw;
    font-weight: bolder;
  }

  #table {
    width: 60%;
    margin: 10px auto 50px;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0 0 10px #aaa;
  }

  #joinourTeam {
    font-size: 2.5vw;
    word-break: break-all;
    margin-bottom: -100px;
  }

  #left-bottom-circle,
  #top-right-circle {
    position: absolute;
  }

  #left-bottom-circle {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    top: 40vh;
    left: 10vw;
    z-index: -100;
    /* border: 2px solid black; */
    background-color: rgb(255, 145, 0);
    box-shadow: inset 0 0 100px 0 rgb(255, 173, 66), 5px 5px 20px 0 rgb(255, 173, 66);
    animation: scaleAnimation 2s infinite alternate ease-in-out;
    /* box-shadow: inset -5px -5px 20px rgb(255, 173, 66),5px 5px 20px 0 #aaa, inset 5px 5px 20px rgb(161, 91, 0); */
  }

  #top-right-circle {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    bottom: 40vh;
    right: 10vw;
    z-index: -100;
    /* border: 2px solid black; */
    background-color: rgb(0, 191, 255);
    box-shadow: inset -5px -5px 20px rgb(85, 213, 255), 5px 5px 20px 0 rgb(85, 213, 255);
    animation: scaleAnimation 2s infinite alternate ease-in-out;
    /* box-shadow: inset -5px -5px 20px rgb(255, 173, 66),5px 5px 20px 0 #aaa, inset 5px 5px 20px rgb(161, 91, 0); */
  }

  #careerFormContainer {
    width: 60%;
    display: flex;
    overflow: hidden;
    /* flexDirection: document.body.offsetWidth > 900 ? row: column;  */
    flex-direction: row;
    padding: 0;
    margin: auto;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0 #aaa;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(50px);
  }
}

@media only screen and (min-width: 1280px) {
  #tescomImgs>img {
    width: 50vw;
    transform: scale(1.3);
    right: 80px;
  }

  #tescomDetails {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 3%;
    position: relative;
    bottom: 20px;
    z-index: 50;
  }

  #carouselHeading {
    font-size: 2.3vw;
  }

  #aboutprimary {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  #abouttextprimary {
    text-align: center;
    margin-left: 10%;
    font-size: 5vw;
  }

  #tescomDetails>h1 {
    margin-top: 10px;
    font-size: 1.15em;
    /* font-size: 2em; */
  }

  #tescomDetails>h2>b {
    font-size: 1.1em;
  }

  #abouttextprimary>h1 {
    font-size: .6em;
    white-space: nowrap;
  }

  #abouttextprimary>p {
    font-size: .3em;
  }

  #aboutImg2 {
    width: 95%;
    clip-path: polygon(0px 1%, 80% 0%, 100% 100%, 0% 100%);
  }

  #aboutSection2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 5%;
    flex-direction: row;
  }


  #ulList>li:nth-child(2) {
    margin-left: 10px;
  }

  #ulList>li:nth-child(3) {
    margin-left: 30px;
  }

  #aboutSection2Txt {
    text-align: center;
    position: relative;
    margin-left: -100px;
  }

  #aboutSection2Txt>h1 {
    position: relative;
    left: -30px;
    white-space: nowrap;
    font-size: 2.5vw;
  }

  #aboutSection2Txt>p {
    font-size: 1.25;
  }

  #aboutSection2Txt>ul {
    font-size: 1.5vw;
  }

  #section3Txt {
    width: 20%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    font-size: 1.5vw;
    font-weight: bolder;
  }

  #table {
    width: 55%;
    margin: 10px auto 50px;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0 0 10px #aaa;
  }

  #joinourTeam {
    font-size: 2.5vw;
    margin-bottom: -100px;
  }

  #left-bottom-circle,
  #top-right-circle {
    position: absolute;
  }

  #left-bottom-circle {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    top: 40vh;
    left: 10vw;
    z-index: -100;
    /* border: 2px solid black; */
    background-color: rgb(255, 145, 0);
    box-shadow: inset 0 0 100px 0 rgb(255, 173, 66), 5px 5px 20px 0 rgb(255, 173, 66);
    animation: scaleAnimation 2s infinite alternate ease-in-out;
    /* box-shadow: inset -5px -5px 20px rgb(255, 173, 66),5px 5px 20px 0 #aaa, inset 5px 5px 20px rgb(161, 91, 0); */
  }



  #top-right-circle {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    bottom: 40vh;
    right: 10vw;
    z-index: -100;
    /* border: 2px solid black; */
    background-color: rgb(0, 191, 255);
    box-shadow: inset -5px -5px 20px rgb(85, 213, 255), 5px 5px 20px 0 rgb(85, 213, 255);
    animation: scaleAnimation 2s infinite alternate ease-in-out;
    /* box-shadow: inset -5px -5px 20px rgb(255, 173, 66),5px 5px 20px 0 #aaa, inset 5px 5px 20px rgb(161, 91, 0); */
  }

  #careerFormContainer {
    width: 60%;
    display: flex;
    overflow: hidden;
    /* flexDirection: document.body.offsetWidth > 900 ? row: column;  */
    flex-direction: row;
    padding: 0;
    margin: auto;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0 #aaa;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(50px);
  }
}

@media only screen and (min-width: 1536px) {
  #tescomImgs>img {
    width: 45vw;
    transform: scale(1.3);
    position: relative;
    right: 150px;
    /* margin: auto; */
  }

  #tescomDetails {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 3%;
    position: relative;
    bottom: 20px;
    z-index: 50;
  }

  #carouselHeading {
    font-size: 2.4vw;
  }

  #aboutprimary {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  #abouttextprimary {
    text-align: center;
    margin-left: 10%;
    font-size: 5vw;
  }

  #abouttextprimary>h1 {
    font-size: .6em;
    white-space: nowrap;
  }

  #abouttextprimary>p {
    font-size: .3em;
  }


  #tescomDetails>h1 {
    margin-top: 10px;
    font-size: 1.5em;
  }

  #tescomDetails>h2>b {
    font-size: 1.45em;
  }

  #aboutImg2 {
    width: 95%;
    clip-path: polygon(0px 1%, 80% 0%, 100% 100%, 0% 100%);
  }

  #aboutSection2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 5%;
    flex-direction: row;
  }

  #ulList>li:nth-child(2) {
    margin-left: 10px;
  }

  #ulList>li:nth-child(3) {
    margin-left: 30px;
  }

  #aboutSection2Txt {
    text-align: center;
    position: relative;
    margin-left: -100px;
  }

  #aboutSection2Txt>h1 {
    position: relative;
    left: -30px;
    white-space: nowrap;
    font-size: 2vw;
  }

  #aboutSection2Txt>p {
    font-size: 1.5;
  }

  #aboutSection2Txt>ul {
    font-size: 1vw;
  }

  #section3Txt {
    width: 20%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    font-size: 1.5vw;
    font-weight: bolder;
  }

  /* #primary-home{
    width: 95%;
    height: 80vh;
    display: flex;
    flex-direction:row;
    margin: 0 auto -50px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 3px solid #ddd; 
  } */
  #table {
    width: 50%;
    margin: 10px auto 50px;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0 0 10px #aaa;
  }

  #joinourTeam {
    font-size: 2vw;
    margin-bottom: -100px;
  }

  #left-bottom-circle,
  #top-right-circle {
    position: absolute;
  }

  #left-bottom-circle {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    top: 50vh;
    left: 15vw;
    z-index: -100;
    /* border: 2px solid black; */
    background-color: rgb(255, 145, 0);
    box-shadow: inset 0 0 100px 0 rgb(255, 173, 66), 5px 5px 20px 0 rgb(255, 173, 66);
    animation: scaleAnimation 2s infinite alternate ease-in-out;
    /* box-shadow: inset -5px -5px 20px rgb(255, 173, 66),5px 5px 20px 0 #aaa, inset 5px 5px 20px rgb(161, 91, 0); */
  }

  #top-right-circle {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    bottom: 50vh;
    right: 15vw;
    z-index: -100;
    /* border: 2px solid black; */
    background-color: rgb(0, 191, 255);
    box-shadow: inset -5px -5px 20px rgb(85, 213, 255), 5px 5px 20px 0 rgb(85, 213, 255);
    animation: scaleAnimation 2s infinite alternate ease-in-out;
    /* box-shadow: inset -5px -5px 20px rgb(255, 173, 66),5px 5px 20px 0 #aaa, inset 5px 5px 20px rgb(161, 91, 0); */
  }

  #careerFormContainer {
    width: 55%;
    display: flex;
    overflow: hidden;
    /* flexDirection: document.body.offsetWidth > 900 ? row: column;  */
    flex-direction: row;
    padding: 0;
    margin: auto;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0 #aaa;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(40px);
  }

  /* 
  #careerFormContainer form input {
    padding: 20px;
  } */
}


@keyframes scaleAnimation {
  from {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
}